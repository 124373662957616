import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import * as Icons from '@src/components/Icon';

import css from './style.module.scss';
import iconMap from './constants';

const Dropdown = (props) => {
	const {
		items,
		isLoading,
		isNothing,
		onSelectItem,
		tip,
		MenuItem,
		selectedValue,
		layout,
	} = props;

	const handleSelectItem = useCallback(
		(item) => (e) => {
			if (onSelectItem) onSelectItem.call(null, item, e);
		},
		[onSelectItem],
	);

	return (
		<div
			className={cn(css.menu, {
				[css[`menu_layout_${layout}`]]: Boolean(layout),
			})}
		>
			<ul className={css.list}>
				{isNothing && (
					<li className={css.nothing}>
						<span>{t('Ничего не найдено :(')}</span>
					</li>
				)}
				{tip && (
					<li className={css.tip}>
						<span>{tip}</span>
					</li>
				)}
				{!isLoading &&
					items.length > 0 &&
					items.map((item) => {
						const {
							suggestionType,
							value,
							text,
							disabled,
							label,
							id,
						} = item;
						const Icon = Icons[`${iconMap[suggestionType]}Icon`];

						return (
							<li
								className={css.item}
								key={`${value}-${text}-${id}`}
							>
								{MenuItem ? (
									<MenuItem
										{...item}
										onMouseDown={handleSelectItem(item)}
										defaultChecked={value === selectedValue}
									/>
								) : (
									<button
										className={css.button}
										type="button"
										onMouseDown={handleSelectItem(item)}
										disabled={disabled}
									>
										{Icon && (
											<div className={css.icon}>
												<Icon />
											</div>
										)}
										<span className={css.value}>
											{label || value}
										</span>
										{text && (
											<span className={css.text}>
												{disabled
													? t(
															'Пока нет предложений в этом городе',
													  )
													: text}
											</span>
										)}
									</button>
								)}
							</li>
						);
					})}
			</ul>
		</div>
	);
};

Dropdown.propTypes = {
	/**
	 * Массив элементов для визуализации меню.
	 */
	items: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
			type: PropTypes.oneOf(['location', undefined]),
			text: PropTypes.string,
			suggestionType: PropTypes.oneOf(['city', 'place', undefined]),
			id: PropTypes.number,
		}),
	),
	/**
	 * Флаг, переключающий состояние загрузки.
	 */
	isLoading: PropTypes.bool,
	/**
	 * Флаг, переключает состояние "Ничего не найдено"
	 */
	isNothing: PropTypes.bool,
	/**
	 * Обработчик события клика по элементу (для совместимости с `popover`'ами
	 * используется событие `onMouseDown`). Принимает два аргумента: объект из
	 * массива `items` и `event`-объект.
	 */
	onSelectItem: PropTypes.func,
	/**
	 * Подсказка для меню.
	 */
	tip: PropTypes.string,
	/**
	 * Внешний вид меню. `extended` - с использованием иконок и второго ряда текста,
	 * `cloud` - `flex` сетка.
	 */
	layout: PropTypes.oneOf(['simple', 'extended', 'cloud']),
	/**
	 * Реакт компонент, элемент меню.
	 */
	MenuItem: PropTypes.elementType,
	/**
	 * Текущее выбранное значение.
	 */
	selectedValue: PropTypes.string,
};

Dropdown.defaultProps = {
	items: [],
	isLoading: false,
	isNothing: false,
	onSelectItem: undefined,
	tip: undefined,
	layout: undefined,
	MenuItem: undefined,
	selectedValue: undefined,
};

export default Dropdown;
