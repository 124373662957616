import React from 'react';
import Icon from '../icon';

import closeM from '../svg/close.m.sprite.svg';

const IconCloseM = (props) => {
	return <Icon {...props} sprite={closeM} />;
};

IconCloseM.propTypes = Icon.propTypes;
IconCloseM.defaultProps = Icon.defaultProps;

export default IconCloseM;
