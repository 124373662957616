import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useFloating, size } from '@floating-ui/react-dom';
import { useUpdateEffect } from 'react-use';
import cn from 'classnames';
import {
	FloatingNode,
	FloatingPortal,
	useFloatingTree,
} from '@floating-ui/react-dom-interactions';

import css from './style.module.scss';

const Popover = ({
	children,
	isOpen,
	offset,
	strategy,
	width,
	isNoBorder,
	isBlock,
	isMaxContent,
	Tag,
}) => {
	const [widthState, setWidth] = useState(width);
	const targetRoot = React.useRef();
	const mountRef = React.useRef();
	const tree = useFloatingTree();

	useUpdateEffect(() => {
		setWidth(width);
	}, [width]);

	const {
		x,
		y,
		reference,
		floating,
		strategy: position,
		update,
	} = useFloating({
		strategy,
		placement: 'bottom-start',
		middleware: [
			size({
				apply({ elements }) {
					const { reference: ref } = elements;
					setWidth(ref.offsetWidth);
				},
			}),
		],
	});

	const floatingStyles = useMemo(
		() => ({
			position,
			top: 0,
			left: 0,
			transform: `translate3d(${Math.round(x)}px, ${Math.round(
				y + offset,
			)}px, 0)`,
			width: isMaxContent ? 'max-content' : widthState,
		}),
		[position, x, y, offset, widthState, isMaxContent],
	);

	const Node = (
		<div
			ref={floating}
			className={cn(css.content, {
				[css.content_noborder]: isNoBorder,
				[css.content_maxContent]: isMaxContent,
			})}
			style={floatingStyles}
		>
			{children[1]}
		</div>
	);

	useEffect(() => {
		global.addEventListener('resize', update);
		tree?.events.on('scroll', update);

		if (mountRef.current) {
			const wrap = mountRef.current.closest('[data-popup-wrap]');
			const root = wrap?.querySelector('[data-popup-portal]');
			targetRoot.current = root;
		}

		return () => {
			global.removeEventListener('resize', update);
			tree?.events.off('scroll', update);
		};
	}, [update, tree]);

	const result = (
		<FloatingNode>
			<Tag ref={reference}>{children[0]}</Tag>
			<span ref={mountRef} />
			<FloatingPortal root={targetRoot.current}>
				{isOpen && Node}
			</FloatingPortal>
		</FloatingNode>
	);

	if (!isBlock) return result;

	return <div className={css.block}>{result}</div>;
};

Popover.propTypes = {
	/**
	 * Дочерние элементы.
	 */
	children: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
	).isRequired,
	/**
	 * Флаг открытого/закрытого поповера.
	 */
	isOpen: PropTypes.bool,
	/**
	 * Отступ от привязанного элемента.
	 */
	offset: PropTypes.number,
	/**
	 * Стратегия позиционирования поповера.
	 */
	strategy: PropTypes.oneOf(['fixed', 'absolute']),
	/**
	 * Ширина поповера.
	 */
	width: PropTypes.number,
	isNoBorder: PropTypes.bool,
	Tag: PropTypes.elementType,
	isBlock: PropTypes.bool,
	isMaxContent: PropTypes.bool,
};

Popover.defaultProps = {
	isOpen: false,
	offset: 8,
	strategy: 'absolute',
	width: undefined,
	isNoBorder: undefined,
	Tag: 'div',
	isBlock: true,
	isMaxContent: false,
};

export default Popover;
