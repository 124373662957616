import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import css from './style.module.scss';

const Btn = (props) => {
	const {
		wrapType,
		color,
		border,
		bg,
		shadow,
		full,
		mix,
		mixIcon,
		ariaLabel,
		label,
		onClick,
		isDisabled,
		round,
		labelMix,
		LeftIcon,
		RightIcon,
		iconSize,
		isSubmit,
		...attr
	} = props;

	const Wrap = attr.href ? 'a' : 'button';

	return (
		<Wrap
			className={cn(css.wrap, mix, {
				[css[`wrap_color_${color}`]]: color,
				[css[`wrap_border_${border}`]]: border,
				[css[`wrap_bg_${bg}`]]: bg,
				[css[`wrap_shadow_${shadow}`]]: shadow,
				[css.wrap_full]: full,
				[css.wrap_round]: round,
			})}
			data-is-submit={isSubmit}
			disabled={isDisabled}
			type={wrapType}
			aria-label={ariaLabel || label}
			onClick={onClick}
			{...attr}
		>
			{LeftIcon && (
				<LeftIcon
					className={cn(css.icon, css.icon_left, mixIcon, {
						[css[`icon_size_${iconSize}`]]: !!iconSize,
					})}
				/>
			)}
			{label && <span className={cn(css.label, labelMix)}>{label}</span>}

			{RightIcon && (
				<RightIcon className={cn(css.icon, css.icon_right, mixIcon)} />
			)}
		</Wrap>
	);
};

Btn.propTypes = {
	onClick: PropTypes.func,
	isDisabled: PropTypes.bool,
	full: PropTypes.bool,
	ariaLabel: PropTypes.string,
	label: PropTypes.string,
	wrapType: PropTypes.oneOf(['submit', 'button']),
	color: PropTypes.oneOf(['inherit', 'purple', 'white']),
	iconSize: PropTypes.oneOf(['small']),
	border: PropTypes.oneOf(['none', 'grey']),
	bg: PropTypes.oneOf(['none', 'white', 'purple', 'plainPurple', 'black']),
	shadow: PropTypes.oneOf(['none', 'default']),
	href: PropTypes.string,
	mix: PropTypes.string,
	mixIcon: PropTypes.string,
	labelMix: PropTypes.string,
	round: PropTypes.bool,
	LeftIcon: PropTypes.elementType,
	RightIcon: PropTypes.elementType,
	isSubmit: PropTypes.bool,
};

Btn.defaultProps = {
	isSubmit: false,
	wrapType: 'button',
	color: 'inherit',
	border: 'none',
	bg: 'white',
	shadow: 'default',
	iconSize: undefined,
	full: false,
	mixIcon: undefined,
	href: undefined,
	mix: undefined,
	ariaLabel: undefined,
	label: undefined,
	onClick: undefined,
	isDisabled: undefined,
	round: false,
	labelMix: undefined,
	LeftIcon: undefined,
	RightIcon: undefined,
};

export default Btn;
